@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
    --app-font: 'Poppins', sans-serif;
    --white: hsl(0deg 0% 100%);
    --black: hsl(0deg 0% 5%);
    --dark-blue: hsl(249, 10%, 26%) ;
    --grayish-blue: hsl(246, 25%, 77%);
    --blue: hsl(248, 32%, 49%);
    --red: hsl(0, 100%, 74%);
    --green: hsl(154, 59%, 51%); 
    --gray: hsl(230.77deg 15.66% 83.73%);
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
  background-image: url(/src/img/bg-intro-desktop.png);
  background-color: var(--red);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--app-font);
}

html, body {
  margin: 0;
  padding: 0;
}

.App .intro__content span {
    color: var(--white);
    font-weight: bold;
    font-size: 50px;
    line-height: 1;
}

.App .intro__content p {
  color: var(--white);
  font-size: 16px;
  margin-top: 25px;
}

.App .sign__up__form .try__it__free {
  background-color: var(--blue);
  text-align: center;
  border-radius: 7px;
  padding: 20px 50px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.App .sign__up__form .try__it__free span {
  color: var(--white);
  font-size: 16px;
  text-align: center;
}

.App .sign__up__form .form__content {
  background-color: var(--white);
  border-radius: 7px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.App .sign__up__form .form__content .input .error {
  color: var(--red);
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
}

.App .sign__up__form .form__content .input .input__content {
  position: relative;
  display: flex;
  align-items: center;
}

.App .sign__up__form .form__content .input .warning-img {
  position: absolute;
  margin-top: 7px;
  right: 20px;
}

.App .sign__up__form .form__content .input .warning-img:before {
  content: url(./img/icon-error.svg);
}

.App .sign__up__form .form__content .input input, select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--gray);
  border-radius: 4px;
  box-sizing: border-box;
}

.App .sign__up__form .form__content .input button {
  width: 100%;
  background-color: var(--green);
  color: var(--white);
  padding: 14px 20px;
  margin: 8px 0;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.App .sign__up__form .form__content .input button:hover {
  background-color: var(--green);
}

.App .sign__up__form .form__content .text {
  text-align: center;
}

.App .sign__up__form .form__content .text span {
  font-size: 12px;
  color: var(--gray);
}

.App .sign__up__form .form__content .text span b {
  color: var(--red);
}

.App .sign__up__form code {
  display: none;
}

@media (max-width: 991px) { 
  .App .intro__content {
    text-align: center;
  }
}

@media (max-width: 768px) { 
  body {
    background-image: url(/src/img/bg-intro-mobile.png);
  }

  .App .intro__content {
    margin-top: 50px;
  }

  .App .intro__content span {
    font-size: 35px;
  }
}

@media (max-width: 375px) { 

}
